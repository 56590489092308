import React from 'react';
import './App.css';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom';
import Upload from './pages/Upload.js';
import Home from './pages/Home.js';
function App() {
    return (
        <div className="container">
            <Router>
                <nav className="nav">
                    <div className="nav-brand"></div>
                    <ul className="nav-items">
                    </ul>
                </nav>
                <Switch>
                    <Route component={Upload} path="/" />
                    <Route component={Home} path="/home" />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
