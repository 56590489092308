import React, { useEffect, useState } from 'react';
import { Image } from 'cloudinary-react';

export default function Home() {
//    const [imageIds, setImageIds] = useState();
//    const loadImages = async () => {
//        try {
//            const res = await fetch('/api/images');
//            const data = await res.json();
//            setImageIds(data);
//        } catch (err) {
//            console.error(err);
//        }
//    };
//    useEffect(() => {
//        loadImages();
//    }, []);
    return (
        <div>
            <h1 className="title">Não Autorizado!</h1>
        </div>
    );
}
